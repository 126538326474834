<template>
  <div class="UserHome">
    
    <div class="bottomDiv1" style="height: auto; margin-bottom: 15px;" >
      <div class="index_title" style="border-bottom: none">订单统计</div>
      <div class="list1" style="font-size: 16px;">
        <router-link class="list2" to="/mobile_index/order_manage"><p>实时订单</p><p>{{ total }}</p></router-link>
        <router-link class="list2"  to="/mobile_index/order_history"><p>历史订单</p><p>{{ total1 }}</p></router-link>
      </div>
      
    </div>
    <div class="bottomDiv1" style="height: auto;">
      <div class="index_title" style="border-bottom: none">历史订单</div>
      <div>
        <el-form label-position="left" style="width: 76vw; display: flex; justify-content: space-between;">
              <el-form-item style="width:50vw; margin-left: 5vw;">
                <el-input
                  v-model="FormData.order_id"
                  placeholder="请输入订单编号"
                ></el-input>
              </el-form-item>
         
              <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
        </el-form>
      </div>
      <div class="tableDiv">
        <el-table
          :key="tableKey"
          :data="tableData"
          stripe="true"
          border
          v-loading="loading"
          height="540px"
          style="
            width: 90%; 
            margin: 20px auto;
            
            overflow-y: auto;
          "
      >
      <el-table-column label="订单编号" prop="order_id"/>
          <el-table-column label="手机号" prop="phone" />
          <el-table-column
            label="详情"
          >
            <template #default="scope" >
              <div>
                <div class="BtnSearch1 BtnSearch" @click="todetal(scope.$index, scope.row)">详情</div>
              </div>
              
            </template>
          </el-table-column>
          <!-- <el-table-column label="设备编号" prop="device_id" />
          <el-table-column label="充电站名称" prop="station_name" />
          <el-table-column label="充电类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column label="充电端口" prop="socket" width="80" />
          <el-table-column label="开始时间" prop="start_time" :formatter="formatDate" />
          <el-table-column label="结束时间" prop="end_time" :formatter="formatDate" />
          <el-table-column label="充电时长" prop="plan_charge_time" width="80"/>
          <el-table-column label="停止原因" prop="stop_charge_status" />
          <el-table-column label="电费" prop="quantity" />
          <el-table-column label="服务费" prop="quantity" />
          <el-table-column label="总金额" prop="quantity" />
          <el-table-column label="支付金额" prop="pay_money" /> -->
         
        </el-table>
        <!-- <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[5, 10, 15, 20]"
            :small="small"
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div> -->
      </div>
    </div>
    <el-dialog
      v-model="Dialog.editLevy"
      title="订单详情"
      width="80%"
      :before-close="closeDialog"
      class="dialog"
    >
      <div class="formDiv1">
        <el-form
          label-position="right"
          label-width="100px"
          style="font-size: 16px;"
        >
          <p>订单号: {{ Dialog.DialogData.order_id }}</p>
          <p>用户手机号: {{ Dialog.DialogData.phone }}</p>
          <p>充电站名称: {{ Dialog.DialogData.station_name }}</p>
          <p>设备编号: {{ Dialog.DialogData.device_id }}</p>
          <p>充电类型: {{ Dialog.DialogData.type_id == '2'?'电动车':'电动汽车' }}</p>
          <p>端口号: {{ Dialog.DialogData.socket }}</p>
          <p>支付方式: {{ Dialog.DialogData.pattern_of_payment }}</p>
          <p>结束时间: {{ Dialog.DialogData.end_time }}</p>
          <p>停止原因: {{ Dialog.DialogData.stop_charge_status }}</p>
          <p>总金额: {{ Dialog.DialogData.quantity }}</p>
          <p>支付金额: {{ Dialog.DialogData.pay_money }}</p>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<style scoped>
/* 标题文字样式 */
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 0.5rem;
}
.formDiv  p{

  height: 30px;
  line-height: 30px;
  /* border-bottom: 1px solid lightblue; */
  /* margin: 0.2rem; */
}
.list1{
  padding: 5px;

}
.list2{
  color: black;
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid lightblue;
}
</style>

<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_history_order,sel_order} from "@/request/api";
import { ElMessage } from "element-plus";
import ProvinceCityCountry from '../../assets/json/address'
export default {
  name: "UserRecord",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime:['',''],
      },
      total:'',
      total1:'',
      tableKey:'',
      tableData: [
        {
          user_id:11,
          contact_way:13256561235,
          wx_id:"001",
          mailbox:"CD001"
        }
      ],
      
      loading: false,
      provinceData: ProvinceCityCountry.address,
      Dialog: {
        editLevy: false,
        DialogData: {},
      },

    });
    const searchBtn = () => {
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
        order_id : data.FormData.order_id
      }
      sel_history_order(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            console.log(res,'获取历史订单列表');
            data.tableData = res.data;
            data.tableKey = Math.random()
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getList = () => {
      console.log(data.FormData,'data.FormData');
    
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
      }
      data.loading = true;
      sel_order(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            // data.tableData = res.data;
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });

      sel_history_order(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            console.log(res,'获取历史订单列表');
            data.tableData = res.data;
            data.total1 = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    
    };
    const todetal = (index,row) => {

      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
    }
    const handleSizeChange = (val)=>{
      data.pageSize = val
    }
    const handleCurrentChange = (val)=>{
        data.currentPage = val
        // 
    }
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      todetal,
      handleSizeChange,
      handleCurrentChange
    };
  },
}
</script>
